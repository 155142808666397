const months = new Array<{ name: string; sunshine: string }>()

months[1] = { name: 'January', sunshine: 'least' }
months[2] = { name: 'February', sunshine: 'tenth' }
months[3] = { name: 'March', sunshine: 'seveth' }
months[4] = { name: 'April', sunshine: 'fifth' }
months[5] = { name: 'May', sunshine: 'fourth' }
months[6] = { name: 'June', sunshine: 'most' }
months[7] = { name: 'July', sunshine: 'second' }
months[8] = { name: 'August', sunshine: 'third' }
months[9] = { name: 'September', sunshine: 'sixth' }
months[10] = { name: 'October', sunshine: 'eighth' }
months[11] = { name: 'November', sunshine: 'eleventh' }
months[12] = { name: 'December', sunshine: 'least' }

const Months = months

export default Months
