import styles from './Column.module.scss'

import React, { CSSProperties } from 'react'
import classNames, { Argument } from 'classnames'

interface Props {
    className?: Argument
    style?: CSSProperties
    stretch?: boolean
}

export const Column: React.FunctionComponent<Props> = ({ style, children, className, stretch = false }) => {
    return (
        <div
            style={style}
            className={classNames(styles.container, className, {
                [styles.stretch]: stretch,
            })}
        >
            {children}
        </div>
    )
}
