import { config } from '../../../config'
import { DailyAverageEnergyView, MonthlyTotalEnergy, OverviewEnergyView, TodayTotalEnergy } from './types'
import { ResponsePromise, client } from '@unicaiot/unica-iot-gallery-core'

export interface IEnergyService {
    getTodayTotalEnergy: () => ResponsePromise<TodayTotalEnergy>
    getMonthTotalEnergy: () => ResponsePromise<MonthlyTotalEnergy>
    getDailyAverageEnergy: () => ResponsePromise<DailyAverageEnergyView>
    getOverviewEnergy: () => ResponsePromise<OverviewEnergyView>
}

export const energyService: IEnergyService = {
    getTodayTotalEnergy() {
        return client.get<TodayTotalEnergy>(config.views.data.energy.today.total)
    },
    getMonthTotalEnergy() {
        return client.get<MonthlyTotalEnergy>(config.views.data.energy.months.total)
    },
    getDailyAverageEnergy() {
        return client.get<DailyAverageEnergyView>(config.views.data.energy.months.days.average)
    },
    getOverviewEnergy() {
        return client.get<OverviewEnergyView>(config.views.data.energy.overview)
    },
}
