import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'

import styles from './DashboardView.module.scss'

import { BasicLayout } from '../../../Core/components/Layout/BasicLayout/BasicLayout'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../routes'
import { config } from '../../../../config'
import { useGetOverviewEnergy } from '../../services/hooks'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    PageError,
    useWindowDimensions,
} from '@unicaiot/unica-iot-gallery-core'
import { Graph } from '../../components/Graph/Graph'
import { BarChart } from '../../components/Graph/BarChart'
import { Row } from '../../../Core/components/Layout/Row/Row'
import { Column } from '../../../Core/components/Layout/Column/Column'
import { Trans } from '@lingui/react'

import { timeFormat } from 'd3-time-format'
import { UnicaLogo } from '../../../Core/components/Logo/UnicaLogo'
import Distances from '../../components/Config/Dinstances'
import { BmwLogo } from '../../../Core/components/Logo/BmwLogo'
import { QRLogo } from '../../../Core/components/Logo/QRLogo'

export const DashboardView: FunctionComponent = () => {
    return <DashboardViewMonthLayout />
}

const DashboardViewMonthLayout: FunctionComponent = () => {
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            history.push(routes.slideOne)
        }, config.dashboardDelay)
    }, [history])

    const result = useGetOverviewEnergy()

    if (result.error) {
        throw new PageError(result.error)
    }

    const date = useMemo(() => {
        return new Date()
    }, [])

    const month = useMemo(() => {
        return date.getMonth() + 1
    }, [date])

    const data = useMemo(() => {
        if (result.data) {
            const unit = result.data.unit
            return {
                ya: { y: Math.trunc(result.data.yearlyAverage), unit: unit },
                da: result.data.monthlyAverages.map(da => {
                    return {
                        x: da.month,
                        y: Math.trunc(da.average),
                        active: da.month === month,
                        unit: unit,
                    }
                }),
                ht: result.data.dailyTotals.map(da => {
                    const calc = Math.trunc((da.total / config.netBatteryConsumption) * 100)
                    const d = Date.UTC(date.getFullYear(), date.getMonth(), da.day)
                    return {
                        x: d,
                        y: calc,
                        h: new Date(d).getMonth(),
                        unit: 'km',
                    }
                }),
                cars: result.data.todayTotal / config.netBatteryContent,
                distance: Math.trunc((result.data.todayTotal / config.netBatteryConsumption) * 100),
                distanceToday: Math.trunc((result.data.hourlyTotal / config.netBatteryConsumption) * 100),
                tt: { y: Math.trunc(result.data.todayTotal), unit: unit },
                lu: new Date(result.data.lastUpdate),
                td: new Date(),
                eh: result.data.effectiveDays,
            }
        }

        return null
    }, [result.data, month, date])
    const distanceToday = useMemo(() => {
        return Distances.get(data?.distanceToday || 0)
    }, [data])

    const [svg, setSvg] = useState()
    useEffect(() => {
        ;(async function () {
            if (distanceToday.map) {
                const { default: importedSvg } = await require(`../../../../assets/maps/${distanceToday.map}.svg`)
                setSvg(importedSvg)
            }
        })()
    }, [distanceToday])
    const wd = useWindowDimensions()

    return (
        <BasicLayout
            loading={false}
            renderStaticStart={() => (
                <div className={styles.container}>
                    <div className={styles.content}>
                        {result.loading || !data ? (
                            <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                        ) : (
                            <Row className={styles.data}>
                                <Column>
                                    <Row align={true} bottom={true}>
                                        <span className={styles.text1}>{timeFormat('%H:%M')(date)}</span>
                                        <span className={styles.text2}>
                                            <Trans
                                                id={'dashboardViewMonth.header'}
                                                message={'Solar panels at Dusseldorp BMW'}
                                            />
                                        </span>
                                    </Row>
                                    <Row align={true}>
                                        <span className={styles.text3}>{timeFormat('%B %d, %Y')(date)}</span>
                                        <span className={styles.text4}>
                                            <Trans
                                                id={'dashboardViewMonth.car'}
                                                message={'Live ix3 ride produced this month'}
                                            />
                                        </span>
                                    </Row>

                                    <Row className={styles.graph}>
                                        <Graph ht={data.ht} width={wd.width * 0.6} height={wd.height - 400} />
                                    </Row>

                                    <Row seporate={false}>
                                        <Column stretch={true}>
                                            <Row align={true} bottom={true}>
                                                <span className={styles.text3}>
                                                    <Trans id={'dashboardViewMonth.cars'} message={'Could charge'} />
                                                </span>
                                                <span>
                                                    <span className={styles.cFirst}>{Math.trunc(data.cars)}</span>
                                                    <span className={styles.cSecond}>{`.${
                                                        (data.cars.toFixed(1) + '').split('.')[1]
                                                    }`}</span>
                                                    <span className={styles.brand}>
                                                        <Trans id={'dashboardViewMonth.brand'} message={'iX3 cars'} />
                                                    </span>
                                                </span>
                                            </Row>
                                            <Row align={true} bottom={true}>
                                                <span className={styles.text3}>
                                                    <Trans id={'dashboardViewMonth.range'} message={'Equivalent to'} />
                                                </span>
                                                <span>
                                                    <span className={styles.rFirst}>{data.distance}</span>
                                                    <span className={styles.rSecond}>
                                                        <Trans id={'dashboardViewMonth.ride'} message={'km to ride'} />
                                                    </span>
                                                </span>
                                            </Row>
                                            <Row align={true} bottom={true}>
                                                <span className={styles.text3}>
                                                    <Trans
                                                        id={'dashboardViewMonth.hours'}
                                                        message={'Since start month'}
                                                    />
                                                </span>
                                                <span>
                                                    <span className={styles.hFirst}>{data.eh}</span>
                                                    <span className={styles.hSecond}>
                                                        <Trans
                                                            id={'dashboardViewMonth.ehours'}
                                                            message={'effective days'}
                                                        />
                                                    </span>
                                                </span>
                                            </Row>
                                            <Row className={styles.logo}>
                                                <UnicaLogo isSmall={true} />
                                            </Row>
                                        </Column>
                                        <Column stretch={true}>
                                            <Row align={true} bottom={true}>
                                                <span className={styles.text3}>
                                                    <Trans
                                                        id={'dashboardViewMonth.produced'}
                                                        message={'Energy produced'}
                                                    />
                                                </span>
                                                <span>
                                                    <span className={styles.pFirst}>{data.tt.y}</span>
                                                    <span className={styles.pSecond}>{data.tt.unit}</span>
                                                </span>
                                            </Row>
                                            <Row align={true}>
                                                <span className={styles.text3}>{`2019-${timeFormat('%Y')(date)}`}</span>
                                                <span className={styles.text4}>
                                                    <Trans
                                                        id={'dashboardViewMonth.daily'}
                                                        message={'Monthly Average Daily Energy'}
                                                    />
                                                </span>
                                            </Row>
                                            <Row className={styles.bar}>
                                                <BarChart da={data.da} width={wd.width * 0.28} ya={data.ya} />
                                            </Row>
                                        </Column>
                                    </Row>
                                </Column>
                                <Column stretch={true}>
                                    <Row align={true} bottom={true}>
                                        <span className={styles.text3}>
                                            <Trans id={'dashboardViewMonth.city'} message={'Today iX3 could get to'} />
                                        </span>
                                        <span className={styles.text2}>{distanceToday.name}</span>
                                    </Row>
                                    <Row align={true}>
                                        <span className={styles.text3}>
                                            <Trans id={'dashboardViewMonth.distanceToday'} message={'Shortest route'} />
                                        </span>
                                        <span>
                                            <span className={styles.dFirst}>{distanceToday.distance}</span>
                                            <span className={styles.dSecond}>{'km'}</span>
                                        </span>
                                    </Row>
                                    <div className={styles.map} style={{ height: wd.height - 480 }}>
                                        <svg viewBox="0 0 100 100">
                                            <image href={svg} />
                                        </svg>
                                    </div>
                                    <Row align={true} bottom={true}>
                                        <span className={styles.text3}>
                                            <Trans id={'dashboardViewMonth.update'} message={'Last update'} />
                                        </span>
                                        <span className={styles.text2}>{data.lu && timeFormat('%H:%M')(data.lu)}</span>
                                    </Row>
                                    <Row align={true} bottom={true}>
                                        <span className={styles.text3}>
                                            <Trans id={'dashboardViewMonth.update'} message={'Scan to visit us'} />
                                        </span>
                                        <span className={styles.bar}>
                                            <QRLogo className={styles.qr} />
                                        </span>
                                        <span className={styles.bar}>
                                            <BmwLogo className={styles.bmw} />
                                        </span>
                                    </Row>
                                </Column>
                            </Row>
                        )}
                    </div>
                </div>
            )}
        />
    )
}
