import styles from './Row.module.scss'

import React, { CSSProperties } from 'react'
import classNames, { Argument } from 'classnames'

interface Props {
    className?: Argument
    style?: CSSProperties
    align?: boolean
    bottom?: boolean
    seporate?: boolean
    stretch?: boolean
    flexEnd?: boolean
}

export const Row: React.FunctionComponent<Props> = ({
    style,
    children,
    className,
    align = false,
    bottom = false,
    seporate = true,
    stretch = false,
    flexEnd = false,
}) => {
    return (
        <div
            style={style}
            className={classNames(styles.container, className, {
                [styles.aling]: align,
                [styles.buttom]: bottom,
                [styles.seporate]: seporate,
                [styles.stretch]: stretch,
                [styles.flexEnd]: flexEnd,
            })}
        >
            {children}
        </div>
    )
}
