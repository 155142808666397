import React, { FunctionComponent } from 'react'
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom'

import {
    TelemetryProvider,
    RedirectorTemplate,
    Shield,
    useResize,
    useScroll,
    client,
} from '@unicaiot/unica-iot-gallery-core'

import { config } from './config'
import { routes } from './routes'
import { DashboardView } from './domain/DashboardView/Views/DashboardView/DashboardView'
import { GlobalErrorView } from './domain/Errors/Views/GlobalErrorView'
import { SlideOneView } from './domain/DashboardView/Views/SlideOneView/SlideOneView'
import { SlideThreeView } from './domain/DashboardView/Views/SlideThreeView/SlideThreeView'
import { SlideTwoView } from './domain/DashboardView/Views/SlideTwoView/SlideTwoView'

client.interceptors.request.use(response => {
    response.headers.Authorization = config.apiKey
    return response
})

export const Redirector: FunctionComponent<RouteComponentProps> = props => {
    return (
        <RedirectorTemplate
            {...props}
            getRedirector={() => {
                const path = config.defaultPath

                return <Redirect to={path} />
            }}
        />
    )
}

const TelemetryProviderWithRouter = withRouter(TelemetryProvider)

export const RootView: FunctionComponent = () => {
    useScroll()

    useResize()

    return (
        <Shield
            getErrorView={() => {
                return <GlobalErrorView />
            }}
        >
            <TelemetryProviderWithRouter instrumentationKey={config.instrumentationKey}>
                <Switch>
                    <Route exact={true} path={routes.index} component={Redirector} />
                    <Route exact={true} path={routes.dashboard} component={DashboardView} />
                    <Route exact={true} path={routes.slideOne} component={SlideOneView} />
                    <Route exact={true} path={routes.slideTwo} component={SlideTwoView} />
                    <Route exact={true} path={routes.slideThree} component={SlideThreeView} />
                </Switch>
            </TelemetryProviderWithRouter>
        </Shield>
    )
}
