import React, { FunctionComponent, Fragment } from 'react'
import { XYPlot, XAxis, VerticalBarSeries, LabelSeries, LineSeries } from 'react-vis'

interface BarChartProp {
    da: { x: number; y: number; active: boolean; unit: string }[]
    ya: { y: number; unit: string }
    width?: number
    height?: number
}

const BarChartInternal: FunctionComponent<BarChartProp> = ({ da, width = 360, height = 162, ya }) => {
    return (
        <Fragment>
            <XYPlot xType={'ordinal'} width={width} height={height} margin={{ top: 30, bottom: 25, left: 0, right: 0 }}>
                <XAxis
                    style={{
                        line: { stroke: '#ffffff', strokeWidth: 0.5 },
                        ticks: {
                            fill: '#ffffff',
                            fontSize: 12,
                            fontWeight: 300,
                            textAlign: 'center',
                            letterSpacing: 1,
                            lineHeight: 'normal',
                            fontStretch: 'normal',
                        },
                    }}
                    tickFormat={d => (('' + d).length < 2 ? '0' + d : d)}
                />

                <VerticalBarSeries
                    data={da.map(d => {
                        return {
                            ...d,
                            color: d.active ? '#9adcff' : '#ffffff',
                        }
                    })}
                    colorType={'literal'}
                    barWidth={0.1}
                />
                <LabelSeries
                    data={da
                        .filter(d => d.active)
                        .map(d => {
                            return {
                                ...d,
                                label: `${d.y}`,
                                yOffset: d.y > ya.y ? -30 : -13,
                                xOffset: (d.x > 6 ? 1 : -1) * (d.y >= 100 ? 13 : 8),
                            }
                        })}
                    style={{
                        fill: '#9adcff',
                        fontSize: 14,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        letterSpacing: 'normal',
                        lineHeight: 'normal',
                        fontStretch: 'normal',
                    }}
                />
                <LabelSeries
                    data={da
                        .filter(d => d.active)
                        .map(d => {
                            return {
                                ...d,
                                label: d.unit,
                                yOffset: d.y > ya.y ? -16 : -3,
                                xOffset: (d.x > 6 ? 1 : -1) * 10,
                            }
                        })}
                    style={{
                        fill: '#9adcff',
                        fontSize: 10,
                        fontWeight: 'normal',
                        textAlign: 'center',
                        letterSpacing: 'normal',
                        lineHeight: 'normal',
                        fontStretch: 'normal',
                    }}
                />
                <LineSeries
                    data={da.map(d => {
                        return { x: d.x, y: ya.y }
                    })}
                    style={{
                        stroke: '#ffffff',
                        strokeWidth: 0.5,
                        opacity: 0.6,
                        strokeDasharray: '4, 2',
                    }}
                />
                <LabelSeries
                    data={[
                        {
                            x: 12,
                            y: ya.y,
                            label: `${ya.y} ${ya.unit}`,
                            yOffset: -20,
                        },
                    ]}
                    style={{
                        fill: '#ffffff',
                        fontSize: 12,
                        fontWeight: 300,
                        textAlign: 'right',
                        letterSpacing: 1,
                        lineHeight: 'normal',
                        fontStretch: 'normal',
                        opacity: 0.6,
                    }}
                />
            </XYPlot>
        </Fragment>
    )
}

const BarChart = BarChartInternal

export { BarChart }
