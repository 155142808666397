import React, { Fragment, FunctionComponent, useEffect, useMemo } from 'react'

import classNames from 'classnames'
import { Trans } from '@lingui/react'

import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    PageError,
    useWindowDimensions,
} from '@unicaiot/unica-iot-gallery-core'

import styles from './SlideThreeView.module.scss'
import { BasicLayout } from '../../../Core/components/Layout/BasicLayout/BasicLayout'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../routes'
import { config } from '../../../../config'
import { BmwLogo } from '../../../Core/components/Logo/BmwLogo'
import { useGetDailyAverageEnergy } from '../../services/hooks'
import { BarChart } from '../../components/Graph/BarChart'
import Months from '../../components/Config/Months'

export const SlideThreeView: FunctionComponent = () => {
    return <SlideThreeViewLayout />
}

const SlideThreeViewLayout: FunctionComponent = () => {
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            history.push(routes.dashboard)
        }, config.slideDelay)
    }, [history])

    const result = useGetDailyAverageEnergy()

    if (result.error) {
        throw new PageError(result.error)
    }

    const month = useMemo(() => {
        return new Date().getMonth() + 1
    }, [])

    const data = useMemo(() => {
        if (result.data) {
            const unit = result.data.unit
            return {
                ya: { y: Math.trunc(result.data.yearlyAverage), unit: unit },
                da: result.data.dailyAverages.map(da => {
                    return {
                        x: da.day,
                        y: Math.trunc(da.average),
                        active: da.day === month,
                        unit: unit,
                    }
                }),
            }
        }

        return null
    }, [result.data, month])

    const wd = useWindowDimensions()

    const width = wd.width * 0.28

    return (
        <BasicLayout
            renderStaticStart={() => (
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.row}>
                            <div className={styles.message}>
                                <Trans
                                    id={'slideThree.message'}
                                    message={
                                        '{month} is the {sunshine} sunniest month in The Netherlands. Our panels currently produce'
                                    }
                                    values={{
                                        month: Months[month].name,
                                        sunshine: Months[month].sunshine,
                                    }}
                                />
                            </div>
                            <BmwLogo />
                        </div>
                        <div className={classNames(styles.row, result.loading ? undefined : styles.infoRow)}>
                            {result.loading ? (
                                <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                            ) : (
                                <Fragment>
                                    <div className={styles.energy}>
                                        <div className={styles.current}>
                                            <div className={styles.info}>
                                                <span className={styles.first}>
                                                    {Math.trunc(result.data?.todayTotal || 0)}
                                                </span>
                                                <span className={styles.second}>{'kWh'}</span>
                                            </div>
                                        </div>
                                        <div className={styles.avg}>
                                            <Trans
                                                id={'slideThree.avg'}
                                                message={'{avg} kWh daily average in {month}'}
                                                values={{
                                                    avg: Math.trunc(data?.da.find(d => d.active)?.y || 0),
                                                    month: Months[month].name,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {data && <BarChart da={data.da} width={width} ya={data.ya} />}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            )}
        />
    )
}
