import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Trans } from '@lingui/react'
import classNames from 'classnames'

import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    PageError,
    useWindowDimensions,
} from '@unicaiot/unica-iot-gallery-core'

import styles from './SlideTwoView.module.scss'
import { BasicLayout } from '../../../Core/components/Layout/BasicLayout/BasicLayout'
import { routes } from '../../../../routes'
import { config } from '../../../../config'
import { BmwLogo } from '../../../Core/components/Logo/BmwLogo'
import { useGetMonthTotalEnegy } from '../../services/hooks'
import Distances from '../../components/Config/Dinstances'

export const SlideTwoView: FunctionComponent = () => {
    return <SlideTwoViewLayout />
}

const SlideTwoViewLayout: FunctionComponent = () => {
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            history.push(routes.slideThree)
        }, config.slideDelay)
    }, [history])

    const result = useGetMonthTotalEnegy()

    if (result.error) {
        throw new PageError(result.error)
    }

    const num = useMemo(() => {
        if (result.data) {
            return (result.data.total / config.netBatteryConsumption) * 100
        }

        return 0
    }, [result.data])

    const distance = useMemo(() => {
        return Distances.get(num)
    }, [num])

    const [svg, setSvg] = useState()
    useEffect(() => {
        ;(async function () {
            if (distance.map) {
                const { default: importedSvg } = await require(`../../../../assets/maps/${distance.map}.svg`)
                setSvg(importedSvg)
            }
        })()
    }, [distance])

    const wd = useWindowDimensions()

    return (
        <BasicLayout
            renderStaticStart={() => (
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.row}>
                            <div className={styles.message}>
                                <Trans
                                    id={'slideTwo.message'}
                                    message={'With the energy produced this month iX3 could get you to'}
                                />
                            </div>
                            <BmwLogo />
                        </div>
                        <div className={classNames(styles.row, result.loading ? undefined : styles.infoRow)}>
                            {result.loading ? (
                                <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                            ) : (
                                <Fragment>
                                    <div className={styles.distance}>
                                        <div className={styles.city}>{distance.name}</div>
                                        <div className={styles.km}>
                                            <Trans
                                                id={'slideTwo.km'}
                                                message={'{km1} km / {km2} km produced'}
                                                values={{ km1: distance.distance, km2: Math.trunc(num) }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={styles.map}
                                        style={{ height: wd.height - 480, width: wd.height - 480 }}
                                    >
                                        <svg>
                                            <image href={svg} />
                                        </svg>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            )}
        />
    )
}
