import styles from './ErrorLayout.module.scss'

import React, { useEffect } from 'react'
import classNames, { Argument } from 'classnames'

import { ActivityIndicator, ActivityIndicatorEnumType } from '@unicaiot/unica-iot-gallery-core'

import { BmwLogo } from '../../Logo/BmwLogo'
import { routes } from '../../../../../routes'
import { config } from '../../../../../config'
import { Trans } from '@lingui/react'

interface Props {
    className?: Argument
}

export const ErrorLayout: React.FunctionComponent<Props> = ({ className }) => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = routes.dashboard
        }, config.errorDelay)
    }, [])

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.content}>
                <div className={styles.row}>
                    <div className={styles.message}>
                        <Trans id={'errorLayout.message'} message={"Be patient. Everything's coming together"} />
                    </div>
                    <BmwLogo />
                </div>
                <div className={classNames(styles.row, { [styles.grow]: true })}>
                    <ActivityIndicator className={styles.loader} size={ActivityIndicatorEnumType.large} />
                </div>
            </div>
        </div>
    )
}
