import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'

import { DailyAverageEnergyView, MonthlyTotalEnergy, OverviewEnergyView, TodayTotalEnergy } from './types'

import { energyService } from './service'

export const useGetTodayTotalEnegy = (): RequestStatus<TodayTotalEnergy | undefined> => {
    return useRequest(energyService.getTodayTotalEnergy, [])
}

export const useGetMonthTotalEnegy = (): RequestStatus<MonthlyTotalEnergy | undefined> => {
    return useRequest(energyService.getMonthTotalEnergy, [])
}

export const useGetDailyAverageEnergy = (): RequestStatus<DailyAverageEnergyView | undefined> => {
    return useRequest(energyService.getDailyAverageEnergy, [])
}

export const useGetOverviewEnergy = (): RequestStatus<OverviewEnergyView | undefined> => {
    return useRequest(energyService.getOverviewEnergy, [])
}
