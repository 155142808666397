const distances = new Array<{ distance: number; name: string; map: string | null }>()

distances[0] = { distance: 0, name: 'Dusseldorp BMW', map: 'map-0' }
distances[1] = { distance: 64, name: 'Den Haag', map: 'map-den-haag' }
distances[2] = { distance: 213, name: 'Brussels', map: 'map-brussels' }
distances[3] = { distance: 423, name: 'Luxembourg', map: 'map-luxembourg' }
distances[4] = { distance: 518, name: 'Paris', map: 'map-paris' }
distances[5] = { distance: 664, name: 'Berlin', map: 'map-berlin' }
distances[6] = { distance: 797, name: 'Copenhagen', map: 'map-copenhagen' }
distances[7] = { distance: 1089, name: 'Milan', map: 'map-milan' }
distances[8] = { distance: 1442, name: 'Stockholm', map: 'map-stockholm' }
distances[9] = { distance: 1784, name: 'Madrid', map: 'map-madrid' }
distances[10] = { distance: 2251, name: 'Lisbon', map: 'map-lisbon' }
//distances[10] = { distance: 2449, name: 'Moscow', map: 'map-moscow' }
distances[11] = { distance: 2863, name: 'Athens', map: 'map-athens' }
distances[12] = { distance: 3823, name: 'Tbilisi', map: 'map-tbilisi' }

const Distances = {
    get(produced: number) {
        const reachable = distances.filter(k => k.distance <= produced)

        return reachable[reachable.length - 1]
    },
}

export default Distances
