import React, { FunctionComponent, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import classNames from 'classnames'

import { ActivityIndicator, ActivityIndicatorEnumType, PageError } from '@unicaiot/unica-iot-gallery-core'

import styles from './SlideOneView.module.scss'
import { BasicLayout } from '../../../Core/components/Layout/BasicLayout/BasicLayout'
import { useGetMonthTotalEnegy } from '../../services/hooks'
import { BmwLogo } from '../../../Core/components/Logo/BmwLogo'
import { Trans } from '@lingui/react'
import { config } from '../../../../config'
import { routes } from '../../../../routes'

export const SlideOneView: FunctionComponent = () => {
    return <SlideOneViewLayout />
}

const SlideOneViewLayout: FunctionComponent = () => {
    const history = useHistory()

    useEffect(() => {
        setTimeout(() => {
            history.push(routes.slideTwo)
        }, config.slideDelay)
    }, [history])

    const result = useGetMonthTotalEnegy()

    if (result.error) {
        throw new PageError(result.error)
    }

    const num = useMemo(() => {
        if (result.data) {
            return result.data.total / config.netBatteryContent
        }

        return 0
    }, [result.data])

    return (
        <BasicLayout
            renderStaticStart={() => (
                <div className={styles.container}>
                    <div className={styles.content}>
                        <div className={styles.row}>
                            <div className={styles.message}>
                                <Trans
                                    id={'slideOne.message'}
                                    message={'This month solar panels on the roof of this building could charge'}
                                />
                            </div>
                            <BmwLogo />
                        </div>
                        <div className={classNames(styles.row, styles.infoRow)}>
                            {result.loading ? (
                                <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                            ) : (
                                <div className={styles.info}>
                                    <span className={styles.first}>{Math.trunc(num)}</span>
                                    <span className={styles.second}>{`.${(num.toFixed(1) + '').split('.')[1]}`}</span>
                                    <span className={styles.cars}>
                                        <Trans id={'slideOne.cars'} message={'iX3 cars'} />
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        />
    )
}
