import React, { FunctionComponent, Fragment, useMemo } from 'react'

import {
    XYPlot,
    XAxis,
    HorizontalGridLines,
    VerticalGridLines,
    LineMarkSeries,
    AreaSeries,
    LabelSeries,
} from 'react-vis'
import '../../../../../node_modules/react-vis/dist/style.css'
import { routes } from '../../../../routes'

import d3Locale from '../../../Core/utils/d3Locale'

interface GraphProp {
    ht: { x: number; y: number; h: number; unit: string }[]
    width?: number
    height?: number
}

const GraphInternal: FunctionComponent<GraphProp> = ({ ht, width = 740, height = 400 }) => {
    const x = useMemo(() => {
        return {
            min: Date.UTC(new Date().getFullYear(), new Date().getMonth(), 1, 3),
            max: Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 17),
        }
    }, [])

    return (
        <Fragment>
            <XYPlot
                xType={'time'}
                width={width}
                height={400}
                xDomain={[x.min, x.max]}
                margin={{ top: 3, bottom: 45, left: 0, right: 0 }}
            >
                <HorizontalGridLines
                    style={{ stroke: '#ffffff', strokeWidth: 0.3, opacity: 0.5 }}
                    tickTotal={(400 - 52) / 20}
                />
                <VerticalGridLines
                    style={{ stroke: '#ffffff', strokeWidth: 0.3, opacity: 0.5 }}
                    tickTotal={width / 20}
                />

                <XAxis
                    tickTotal={10}
                    style={{
                        line: { stroke: '#ffffff', strokeWidth: 0.5 },
                        ticks: {
                            fill: '#ffffff',
                            fontSize: 12,
                            fontWeight: 300,
                            textAlign: 'center',
                            letterSpacing: 1,
                            lineHeight: 'normal',
                            fontStretch: 'normal',
                        },
                    }}
                    tickFormat={d3Locale}
                    marginTop={20}
                />

                <AreaSeries
                    color={'rgba(255, 255, 255, 0.2)'}
                    style={{ opacity: 0.5 }}
                    data={[
                        { x: x.min, y: ht.length ? ht[0].y : 0, y0: ht.length > 1 ? ht[ht.length - 1].y : 0 },
                        { x: x.max, y: ht.length ? ht[0].y : 0, y0: ht.length > 1 ? ht[ht.length - 1].y : 0 },
                    ]}
                />
                <LineMarkSeries
                    data={ht.map(d => {
                        return { ...d, opacity: d.h > 8 && (d.h / 3) % 1 === 0 ? 1 : 0 }
                    })}
                    curve={'curveMonotoneX'}
                    lineStyle={{ stroke: '#9adcff', strokeWidth: 1 }}
                    markStyle={{ stroke: '#9adcff', opacity: 0.5, fill: '#9adcff', strokeWidth: 0 }}
                />

                <LabelSeries
                    data={ht
                        .filter(d => {
                            return d.h > 8 && (d.h / 1) % 1 === 0
                        })
                        .map(d => {
                            return {
                                ...d,
                                label: `${d.y}`,
                                xOffset: d.h < 12 ? -40 : d.h === 12 ? -36 : 30,
                                yOffset: d.h <= 12 ? 1 : 4,
                            }
                        })}
                    style={{
                        fill: '#9adcff',
                        fontSize: 12,
                        fontWeight: 'bold',
                        textAlign: 'center',
                        letterSpacing: 'normal',
                        lineHeight: 'normal',
                        fontStretch: 'normal',
                    }}
                />
            </XYPlot>
        </Fragment>
    )
}

const Graph = GraphInternal

export { Graph }
