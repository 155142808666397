/* eslint-disable  @typescript-eslint/no-explicit-any */

import React, { Fragment } from 'react'

import { ErrorLayout } from '../../Core/components/Layout/ErrorLayout/ErrorLayout'

export interface ErrorViewParams {
    status?: any
}

export const GlobalErrorView: React.FunctionComponent<ErrorViewParams> = ({ status }) => {
    return status !== 401 ? <ErrorLayout /> : <Fragment />
}
