import { Config as ConfigGallery, config as configGallery, getEnv } from '@unicaiot/unica-iot-gallery-core'

export interface Config extends ConfigGallery {
    defaultLocale: string

    apiKey: string

    views: {
        data: {
            energy: {
                today: {
                    total: string
                }
                months: {
                    total: string
                    days: {
                        average: string
                    }
                }
                overview: string
            }
        }
    }

    netBatteryContent: number
    netBatteryConsumption: number

    dashboardDelay: number
    slideDelay: number
    errorDelay: number
}

export const config: Config = {
    ...configGallery,

    defaultLocale: 'nl',

    apiKey: getEnv('API_KEY'),

    views: {
        data: {
            energy: {
                today: {
                    total: 'api/views/data/energy/today/total',
                },
                months: {
                    total: 'api/views/data/energy/month/total',
                    days: {
                        average: 'api/views/data/energy/months/days/average',
                    },
                },
                overview: 'api/views/data/energy/overview',
            },
        },
    },

    netBatteryContent: parseFloat(getEnv('NET_BATTERY_CONTENT', '73.83')),
    netBatteryConsumption: parseFloat(getEnv('NET_BATTERY_CONSUMPTION', '19')),

    dashboardDelay: 30000,
    slideDelay: 10000,
    errorDelay: 5000,
}
